import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

import { markManualIdCheck, useMutateApiData } from '../../util/api';
import { ErrorMessage, SimpleCard } from '../common';
import { SectionField } from '../common/section';

interface ManualIdCheckProps {
  reservationId: string;
  manualIdChecked: boolean;
  manualIdCheckEnabled: boolean;
  refetchReservation: () => Promise<unknown>;
}

const ManualIdCheck: React.FC<ManualIdCheckProps> = ({
  reservationId,
  manualIdCheckEnabled = false,
  manualIdChecked = false,
  refetchReservation,
}) => {
  const { t } = useTranslation();

  const { error, isLoading, mutateAsync: markManualIdCheckRequest } = useMutateApiData(markManualIdCheck);

  const markAsChecked = async (): Promise<void> => {
    await markManualIdCheckRequest([reservationId]);
    await refetchReservation();
  };

  if (!manualIdCheckEnabled) {
    return null;
  }

  const showButton = !manualIdChecked;
  const idCheckedText = manualIdChecked ? "✅ yes" : '❌ no';
  return (
    <SimpleCard title={t('sections.manualIdCheck')}>
      <SectionField label='ID Checked'>{idCheckedText}</SectionField>
      {showButton && (
        <Box flexDirection="row">
          <Button variant="outlined" color="primary" onClick={markAsChecked} disabled={isLoading}>
            {t('general.markAsChecked')}
          </Button>

          {isLoading && <Typography>{t('general.loading')}</Typography>}
        </Box>
      )}

      {error && <ErrorMessage message={t('general.error')} />}
    </SimpleCard>
  );
};

export default ManualIdCheck;
