import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { EnumReservationStatus, HskDelayResponse, ReservationDetails } from '../../types';
import { SimpleCard } from '../common';
import {
  LanguageSwitch,
  MandatoryRegistration,
  ManualIdCheck,
  MisconductReport,
  PaymentDetails,
  PinCodes,
  RegistrationStatus,
} from '.';
import EventsLog from './EventsLogs/EventsLog';
import GuestLinks from './GuestLinks';
import { InvoiceRecipients } from './InvoiceRecipients';
import { MessagingSection } from './MessagingSection';
import { ProofOfId } from './ProofOfId/ProofOfId';
import HskDelay from './HskDelay';

interface Props extends ReservationDetails {
  refetchReservation: () => Promise<any>;
  hskDelay: HskDelayResponse;
}

const ReservationSection: React.FC<Props> = ({ refetchReservation, reservation, property, hskDelay }) => {
  const { t } = useTranslation();

  const hasCodes = Object.keys(reservation.pinCodes).length > 0;
  const isReservationInHouse = reservation.reservationStatus === EnumReservationStatus.InHouse;

  return (
    <Grid style={{ paddingBottom: '65px' }} container spacing={2}>
      <Grid item sm={4} xs={12}>
        <MessagingSection reservation={reservation} />

        <RegistrationStatus reservation={reservation} />

        <SimpleCard title={t('sections.recipientsEmail')}>
          <InvoiceRecipients invoiceRecipients={reservation?.invoiceRecipients} />
        </SimpleCard>
        {reservation.foodjiVoucher && (
          <SimpleCard title={t('sections.breakfast')}>
            {t('breakfast.foodji')}
            {reservation.foodjiVoucher.code}
          </SimpleCard>
        )}

        <SimpleCard title={t('sections.payment')}>
          <PaymentDetails reservation={reservation} />
        </SimpleCard>

        <SimpleCard title={t('sections.misconductReport')}>
          <MisconductReport id={reservation.id} misconduct={reservation.misconduct} />
        </SimpleCard>
      </Grid>

      <Grid item sm={4} xs={12}>
        <ManualIdCheck
          reservationId={reservation.id}
          manualIdCheckEnabled={property.manualIdCheckEnabled}
          manualIdChecked={reservation.manualIdChecked}
          refetchReservation={refetchReservation}
        />

        {(hasCodes || isReservationInHouse) && (
          <SimpleCard title={t('sections.pinCodes')}>
            <PinCodes reservation={reservation} refetchReservation={refetchReservation} />
          </SimpleCard>
        )}
        {hskDelay?.reservationId && (
          <SimpleCard title={t('sections.pinCodes')}>
            <HskDelay hskDelay={hskDelay} />
          </SimpleCard>
        )}
        <GuestLinks reservation={reservation} />
      </Grid>

      <Grid item sm={4} xs={12}>
        <EventsLog reservationId={reservation.id} timezone={property?.timezone} />

        {property?.mandatoryOnlineCheckIn && (
          <SimpleCard title={t('sections.mandatoryRegistration')}>
            <MandatoryRegistration id={reservation.id} reservation={reservation} />
          </SimpleCard>
        )}
        <SimpleCard title="Proof of ID">
          <ProofOfId reservation={reservation} />
        </SimpleCard>

        <SimpleCard title={t('sections.language')}>
          <LanguageSwitch id={reservation.id} language={reservation.language} />
        </SimpleCard>
      </Grid>
    </Grid>
  );
};

export default ReservationSection;
