import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

export const de = {
  general: {
    ok: 'Ok',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    error: 'Es ist ein Fehler aufgetreten. Bitte später erneut versuchen',
    noData: 'Keine Daten gefunden. Bitte später erneut versuchen',
  },
  tabs: {
    demo: 'Demo',
  },
};

export const en = {
  general: {
    ok: 'Ok',
    confirm: 'Confirm',
    cancel: 'Cancel',
    error: 'An error occured. Please try later.',
    noData: 'No data found. Please try again later',
    save: 'Save',
    loading: 'Loading...',
    selection: 'Selection',
    logout: 'Logout',
    login: 'Login',
    selectAll: 'Select all',
    open: 'Open',
    markAsChecked: 'Mark as checked',
  },
  messages: {
    bookingConfirmation: 'Booking Confirmation',
    checkinInfo: 'Checkin Info',
    checkinReminder: 'Checkin Reminder',
    invoice: 'Invoice',
    houseRules: 'House Rules',
    cancelled: 'Cancellation confirmation',
    extensionConfirmation: 'Extension Confirmation',
    welcomeMessage: 'Welcome Message',
    preCheckoutInfo: 'Pre-Checkout Info',
    checkoutReminder: 'Checkout Reminder',
    resend: 'Resend',
    sendNow: 'Send Now',
  },
  sections: {
    bulkEmail: 'Bulk E-Mails',
    bulkSms: 'Bulk Sms',
    bulkMessageSchedules: 'Bulk Message Schedules',
    messages: 'Messaging',
    pinCodes: 'Pin Codes',
    tripManagement: 'Tripmanagement',
    guestDocuments: 'Guest Documents',
    mandatoryRegistration: 'Mandatory Registration',
    mandatoryCovPass: 'Mandatory Cov Pass check',
    recipientsEmail: 'Invoice Recipients Email',
    payment: 'Payment',
    extras: 'Extra service',
    language: 'Language',
    misconductReport: 'Misconduct Report',
    somethingWrong: 'Something went wrong',
    deactivate: 'Deactivate',
    activate: 'Activate',
    paymentLink: 'Generate payment link',
    guestLinks: 'Guest Links',
    breakfast: 'Breakfast',
    manualIdCheck: '🪪 Manual ID Check',
  },
  breakfast: {
    foodji: 'Foodji Voucher Code: ',
  },
  pinCodes: {
    comfortCode: 'Comfort code',
    backupCode: 'Backup code',
    comfortLink: 'Comfort link',
    notSet: 'Not set',
    activateCode: 'Activate code',
    regenerateCode: 'Regenerate Codes',
    deleteCode: 'Delete Code',
    deleteCodeExplained: 'This will delete the current code.',
    regenerateCodeExplained:
      'This will REMOVE the current pin code from the underlying system (if previously created) and create a new one. Please use this carefully as this should only be used in special cases (e.g. Guest asks for a new pin code for privacy reasons)',
    invalidateBackup: 'Invalidate backup code',
    invalidateBackupExplained:
      'This will set the validity of the current backup pin code to the checkout date of the current reservation and generate a new backup code. Please only use this, if you gave the current backup code to a guest.',
    activateCodeExplanation: 'This will modify the activation time to now',
    reveal: 'reveal',
    revealCode: 'Reveal code',
  },
  paymentDetails: {
    paymentAR: 'Payment AR',
    payerEmail: 'payer email e.g email@gmail.com',
    sendPaymentLink: 'Generate Payment Link',
    paymentLinkHelper: 'Leave empty to use main guest email',
  },
  mandatoryRegistration: {
    title: 'Mandatory registration disabled',
    switchOn: 'Switch on',
    switchOff: 'Switch off',
  },
  guestDocuments: {
    title: 'Cov pass status:',
    approved: 'Approved',
    rejected: 'Rejected',
    needsChecking: 'Needs Checking',
    approve: 'Approve',
    reject: 'Reject',
    approveTitle: 'Do you want to approve all documents?',
    rejectTitle: 'Do you want to reject all documents?',
    approveText: 'This will enable the guest to check in.',
    rejectText: 'After rejecting, please inform the guest to provide the required documents.',
    uploadBtnText: 'Upload',
    uploadedSuccessfully: 'All the files have been uploaded successfully',
    notAllDocumentsProvided: 'Not all guests have provided their documents yet. Please wait for the checkin to finish.',
    downloadDocuments: 'Download all Docs',
    downloadCovidDocuments: 'Download Covid Docs',
    downloadIdDocuments: 'Download ID Docs',
    noDocumentCheckRequired: 'Document check is not required.',
    noIdCheckNeeded: 'ID uploads are not active, so no check is required.',
  },
  language: {
    preferredLanguage: 'Preferred Language',
    english: 'English',
    german: 'German',
    french: 'French',
    spanish: 'Spanish',
    italian: 'Italian',
    dutch: 'Dutch',
    czech: 'Czech',
  },
  bulkMessaging: {
    emailType: 'Email Type',
    success: 'Success',
    pending: 'Pending',
    emailSubject: 'Email Subject',
    emailContent: 'Email Content',
    reservationStatus: 'Reservation Status',
    smsContent: 'Sms Text',
    scheduleMessage: 'Schedule message for a later time',
    emailPopupHeading: 'Schedule bulk email',
    smsPopupHeading: 'Schedule bulk sms',
    selectDate: 'Date',
    selectTime: 'Time',
    timeLabel: 'Hrs',
    deletedConfirmTitle: 'Delete bulk message',
    deletedConfirmText: 'Do you want to delete this scheduled task?',
    reservationDate: 'Reservation date filter',
    reservationStatusLabel: 'Status',
    reservationIds: 'Filter by reservation ids',
    reservationIdsHelper: 'Add comma(,) or semicolon(;) to separate reservation ids',
    all: 'All',
    filterType: 'Filter type',
    columnHeading: {
      status: 'Status',
      actions: 'Actions',
      type: 'Message Type',
      scheduledAt: 'Scheduled At',
      reservationStatus: 'Reservation Status',
      reservationIds: 'Reservation Ids',
      content: 'Content (EN)',
    },
  },
  misconduct: {
    type: 'Misconduct Type',
    degree: 'Misconduct Degree',
    additionalComment: 'Additional Comment',
  },
  guestWatchList: {
    pageHeader: 'Suspicious Guest List',
    active: 'Active',
    archivedList: 'Archived List',
  },

  tripManagement: {
    open: 'Open trip management',
  },
  onlineCheckin: {
    open: 'Open Online Checkin',
    openBreakfast: 'Open Breakfast Selection',
  },
  weekly: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    nothing: 'Nothing to display',
    deselectAll: 'Deselect All',
    selectAll: 'Select All',
    clean: 'to clean',
  },
  eventsLog: {
    title: 'Events Log',
    showMore: 'Show More',
    showLess: 'Show Less',
  },
  recipientsEmail: {
    noEmails: 'No emails recipients for the invoice',
  },
};
export const initI18 = (): void => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
};
